import React, { useState } from 'react';

import { Button, Form, Input, Typography } from 'antd';
import { Link } from 'gatsby';
import LocalizedStrings from 'react-localization';
import { StringParam, useQueryParam } from 'use-query-params';

import { FormPageLayout } from '../components/Layouts';
import { apiRequester, handleError, handleSuccess } from '../utility';

const { Title, Paragraph } = Typography;
const strings = new LocalizedStrings({
    en: {
        resetTitle: 'Reset your Virtooally Password',
        resetSuccess: 'Your password has been reset. Please proceed to login.',
        newPass: 'New Password',
        enterPass: 'Please enter your password',
        enterPassBelow: 'Enter your new secret below',
        reset: 'Reset password',
        backToLogin: 'Back to login',
    },
    de: {
        resetTitle: 'Setzen Sie Ihr Virtooally-Passwort zurück',
        resetSuccess: 'Bitte warten Sie, während wir Ihre E-Mail-Adresse verifizieren.',
        newPass: 'Neues Passwort',
        enterPass: 'Bitte geben Sie Ihr Passwort ein',
        enterPassBelow: 'Bitte geben Sie Ihr neues Passwort ein',
        reset: 'Passwort zurücksetzen',
        backToLogin: 'Zurück zum Login',
    },
});

const ResetPasswordPage = () => {
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useQueryParam('t', StringParam);
    const onFinish = async (values: { newPassword: string }) => {
        try {
            setLoading(true);
            const response = await apiRequester.resetPassword({
                ...values,
                token: token ? token : '',
            });
            console.info(response);
            handleSuccess(strings.resetSuccess!);
            setLoading(false);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };
    return (
        <FormPageLayout>
            <div style={{ marginBottom: '2rem' }}>
                <Title level={2} style={{ marginBottom: '0' }}>
                    {strings.resetTitle}
                </Title>
                <Paragraph>{strings.enterPassBelow}</Paragraph>
            </div>
            <Form onFinish={onFinish}>
                <Form.Item
                    label={strings.newPass}
                    labelCol={{ span: 24 }}
                    name="newPassword"
                    rules={[{ required: true, message: strings.enterPass }]}
                >
                    <Input.Password style={{ height: '3rem' }} />
                </Form.Item>
                <Form.Item>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Button type="primary" htmlType="submit" loading={loading} style={{ height: '3rem' }}>
                            {strings.reset}
                        </Button>
                        <Link to="/login">{strings.backToLogin}</Link>
                    </div>
                </Form.Item>
            </Form>
        </FormPageLayout>
    );
};

export default ResetPasswordPage;
